<template>
  <section class="content d-flex flex-column justify-content-center align-items-center maintenance-page">
    <b-container fluid="sm">
      <img :src="threeDoctorsSVG" class="mb-5 three-doctors-svg" />
      <h1 class="mb-3">Site is under maintainance</h1>
      <p>
        Hello!✨️</p>
        <p>⚠️ We're currently undergoing portal maintenance until Sunday 0900hrs.</p>
        <p>📧 If you would like for us to notify you when our portal is up, leave your contact number here: <a href="https://forms.gle/rTmnC2AKmpCw5Tp98">https://forms.gle/rTmnC2AKmpCw5Tp98</a></p>
        <p>In the meantime, do check out our FAQ at <a href="https://home.rapidr.sg/faqs">https://home.rapidr.sg/faqs</a> for any queries you might have.</p>
        <p>Thank you, and we look forward to seeing you soon ✨️</p>
      <p>Apologies for the inconvenience caused!
      </p>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      threeDoctorsSVG: require("@/assets/three-doctors.svg"),
    };
  },
  methods: {
    maintenanceContactForm() {
      window.open("https://forms.gle/ioZa5fiT3HHcPUSLA", "_blank");
    },
  },
};
</script>

<style>
.maintainance-page {
  height: 100vh;
  font-size: 2rem;
  font-weight: 600;
}

.three-doctors-svg {
  max-width: 500px;
}

.hyperlink {
  color: blue;
  text-decoration: underline;
}
</style>
