<template>
  <log-in/>
</template>
<script>
import LogIn from "../../components/patient/logIn/LogIn.vue";
export default {
  name: "my-register",
  metaInfo: {
    title: "Log in",
    meta: [
      {
        name: "description",
        content:
          "Teleconsultations from $6.90 with access to experienced MOH licensed doctors. Obtain medical certificates 24/7. No app installation needed.",
      },
    ],
  },
  components: {
    LogIn,
  },
};
</script>
