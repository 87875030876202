<template>
  <div id="app" v-if="maintenance == false">
    <spinner-overlay v-if="isLoading" />
    <splash-screen v-if="!isDoctor" />
    <my-nav-bar v-if="isDoctor" />
    <router-view v-if="checkViewPort" />
    <div v-else>
      <patient-view-port-error v-if="currentUserType == 'patient'" />
      <doctor-view-port-error v-if="currentUserType == 'doctor'" />
    </div>
  </div>
<div v-else>
    <under-maintenance />
  </div>
</template>

<script>

import MyNavBar from "./components/doctor/nav/MyNavBar.vue";
import SplashScreen from "./components/patient/SplashScreen.vue";
import PatientViewPortError from "./components/patient/PatientViewPortError.vue";
import DoctorViewPortError from "./components/doctor/DoctorViewPortError.vue";
import spinnerOverlay from "./components/spinnerOverlay.vue";
import UnderMaintenance from "./views/UnderMaintenance.vue";

export default {
  name: "App",
  metaInfo: {
    title: "RapiDr SG",
    titleTemplate:
      "%s | Teleconsultations from $6.90 with access to experienced MOH licensed doctors. Obtain medical certificates 24/7. No app installation needed.",
  },
  components: {
    SplashScreen,
    MyNavBar,
    PatientViewPortError,
    DoctorViewPortError,
    spinnerOverlay,
    UnderMaintenance,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      currentUserType: null,
      maintenance: false,
    };
  },
  created() {
    this.$store.subscribeAction({
      after: (action) => {
        if (action.type === "activateSnackBar") {
          this.$bvToast.toast(this.$store.state.snackBarMsg, {
            autoHideDelay: 5000,
            variant: this.$store.state.snackBarVariant,
            solid: true,
            queue: false,
          });
          if (this.$store.state.snackBarMsg == "Unauthorized") {
            this.clearStore();
          }
        }
      },
    });
  },
  methods: {
    async clearStore() {
      await this.$store.dispatch("logout");
      const currentPath = this.$router.currentRoute.path;
      if (currentPath != "/login" && currentPath != "/doctor/login") {
        const isDoctorRoute = currentPath.includes("doctor");
        if (isDoctorRoute) {
          this.$router.push("/doctor/login");
        } else {
          this.$router.push("/login");
        }
      }
    },
    getCurrentUserType() {
      if (
        this.$router.currentRoute.path.toLowerCase().includes("/verifymc") ||
        this.$router.currentRoute.path.toLowerCase().includes("/verifyemail")
      ) {
        this.currentUserType = "none";
      } else if (
        this.$router.currentRoute.path.toLowerCase().includes("doctor")
      ) {
        this.currentUserType = "doctor";
      } else {
        this.currentUserType = "patient";
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    isDoctor() {
      return this.$store.getters.isDoctor;
    },
    checkViewPort() {
      if (this.currentUserType == "doctor") {
        return this.windowWidth > 567;
      } else if (this.currentUserType == "patient") {
        return this.windowWidth <= 567;
      }
      return this.windowWidth > 0;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  watch: {
    $route: function () {
      this.getCurrentUserType();
    },
  },
  mounted() {
    this.getCurrentUserType();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: black;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>